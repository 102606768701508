import React from 'react';
import axios from 'axios';
import config from '../config'
import { Redirect } from 'react-router-dom';

export default class Pl extends React.Component {
    state = {
        timer: 31,
        handler: <div className="wait">Please wait</div>
    }

    componentDidMount() {
        document.title = "OTTstar Advertisement"
        axios.get(`${config.apiurl}/get/${this.props.match.params.id}`)
            .then(res => {
                if (res.data.status === true) {

                    var timerF = setInterval(() => {
                        if (this.state.timer === 0) {
                            this.setState({ handler: <a href={res.data.main_link}><div className="final">Continue</div></a> })
                            clearInterval(timerF);
                            document.title = `Redirecting...`
                            window.location = res.data.main_link
                        } else {
                            this.setState({ timer: this.state.timer - 1 })
                            this.setState({ handler: <div className="wait">{this.state.timer} second</div> })
                        }
                    }, 1000);

                } else {
                    this.setState({ handler: <Redirect to="/not-found"></Redirect> })
                }
            })
    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1 className="logo"><b>OTT</b>star</h1>
                    {this.state.handler}
                </div>
                <div className="content">
                    <iframe title="AD" src="./ad.html" className="iframe"></iframe>
                </div>
            </div>
        )
    }
}
